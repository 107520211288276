import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

import { Link, graphql, useStaticQuery, navigate } from "gatsby";

import "../styles/index.css";

import * as styles from "../styles/index.module.css";
import AmazonAd from "../components/amazonAd";

const IndexPAge = () => {
  const [ad, setAd] = useState({});
  const [rearrangedPosts, setRearrangedPosts] = useState([]);
  const data = useStaticQuery(graphql`
    query MyQuery {
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              title
              date
              description
              author
              imageurl
              imagealt
              isodate
            }
            html
            fields {
              slug
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const rearranged = data.allMarkdownRemark.edges.sort((a, b) => {
      return (
        new Date(b.node.frontmatter.date) - new Date(a.node.frontmatter.date)
      );
    });
    setRearrangedPosts(rearranged);
  }, [data.allMarkdownRemark.edges]);

  useEffect(() => {
    const randomvalue = Math.floor(Math.random() * 1600);

    switch (true) {
      case randomvalue < 310:
        setAd({
          href: "https://www.jdoqocy.com/click-100376026-14483390",
          src:
            "https://i.ibb.co/tJ9px02/affiliate-new-years-campaign-winter-couple-1200x628.png",
          alt: "",
        });
        break;

      case randomvalue > 310 && randomvalue < 630:
        setAd({
          href: "https://www.dpbolvw.net/click-100376026-14326263",
          src: "https://www.awltovhc.com/image-100376026-14326263",
          alt: "Popular Domains for just 99 Cents at Namecheap!",
        });
        break;

      case randomvalue > 630 && randomvalue < 960:
        setAd({
          href: "https://www.jdoqocy.com/click-100376026-13868749",
          src: "https://www.ftjcfx.com/image-100376026-13868749",
          alt: "",
        });
        break;

      case randomvalue > 960 && randomvalue < 1200:
        setAd({
          href: "https://www.anrdoezrs.net/click-100376026-11751862",
          src: "https://www.awltovhc.com/image-100376026-11751862",
          alt: "Get up to 30% off all new products with GoDaddy!",
        });
        break;

      case randomvalue > 1200 && randomvalue < 1600:
        setAd({
          href: "https://www.kqzyfj.com/click-100376026-10833186",
          src: "https://www.awltovhc.com/image-100376026-10833186",
          alt: "Get Hosting for $1.00*/mo with GoDaddy",
        });
        break;

      default:
        setAd({
          href: "https://www.jdoqocy.com/click-100376026-14483390",
          src: "https://www.tqlkg.com/image-100376026-14483390",
          alt: "",
        });
    }
  }, []);

  return (
    <Layout>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>Webdevolution</title>
        <meta name="title" content="Webdevolution"></meta>
        <meta
          name="description"
          content="Web development articles, tips and tutorials for software developers and content creators."
        />
        <meta name="image" content="../assets/webdevolution-cover1.png" />
        <link rel="icon" href="https://www.webdevolution.com/favicon.ico" />
        <link
          href="https://www.webdevolution.com/favicon.ico"
          rel="apple-touch-icon"
          sizes="96x96"
        />
        <meta
          name="og:image"
          content="https://i.ibb.co/k591yTd/webdevolution-cover1.png"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.webdevolution.com/" />
        <meta property="twitter:title" content="Webdevolution" />
        <meta
          property="twitter:description"
          content="Web development articles, tips and tutorials for software developers and content creators."
        />
        <meta
          property="twitter:image"
          content="https://i.ibb.co/k591yTd/webdevolution-cover1.png"
        />
      </Helmet>
      <div>
        <div className={`${styles.devToolsSection}`}>
          <h1>Articles</h1>
          <p>
            Guides and tutorials on software engineering, web development, cloud
            technologies and other stuff.
          </p>
          <div className={styles.blogArticlesList}>
            {rearrangedPosts.map((i, index) => {
              return index < 15 ? (
                <div
                  className={styles.blogArticlaCard}
                  key={i.node.frontmatter.title}
                >
                  <Link to={`/blog/${i.node.fields.slug}/`}>
                    <img
                      className={styles.postImage}
                      src={i.node.frontmatter.imageurl}
                      alt=""
                    />
                    <div className={styles.cardInfo}>
                      <h1 className="postHeader">{i.node.frontmatter.title}</h1>
                      <p className={styles.postDataAndAuthor}>
                        <time datetime={i.node.frontmatter.isodate}>
                          {i.node.frontmatter.date}
                        </time>
                      </p>
                      <p className={styles.postDescription}>
                        {i.node.frontmatter.description}
                      </p>
                    </div>
                  </Link>
                </div>
              ) : null;
            })}
          </div>
          <button onClick={() => navigate("/blog")} className={styles.btn}>
            All Articles <span>→</span>
          </button>
        </div>
        <div className={`${styles.devToolsSection}`}>
          <h1>Developer tools</h1>
          <p>
            Collection of{" "}
            <span style={{ fontWeight: 600, color: "var(--color-header)" }}>
              free
            </span>
            &nbsp;high quality tools and resources from all over the internet.
          </p>
          <div className={styles.devToolsList}>
            <a href="https://www.gradientos.app">
              <div className={styles.devToolCard}>
                <img src="https://www.gradientos.app/favicon.ico" alt="" />
                <div className={styles.devToolCardInfo}>
                  <h2 className={styles.devToolCardInfoHeader}>Gradientos</h2>
                  <p className={styles.devToolCardInfoText}>
                    Gradientos is a gradient tool
                  </p>
                </div>
              </div>
            </a>
            <a href="https://lucide.dev">
              <div className={styles.devToolCard}>
                <img src="https://lucide.dev/favicon.ico" alt="" />
                <div className={styles.devToolCardInfo}>
                  <h2 className={styles.devToolCardInfoHeader}>Lucide</h2>
                  <p className={styles.devToolCardInfoText}>
                    Beautiful & consistent icon toolkit.
                  </p>
                </div>
              </div>
            </a>
            <a href="https://www.scrollbar.app">
              <div className={styles.devToolCard}>
                <img
                  src="https://scrollbar.app/android-chrome-192x192.png"
                  alt=""
                />
                <div className={styles.devToolCardInfo}>
                  <h2 className={styles.devToolCardInfoHeader}>
                    Scrollbar.app
                  </h2>
                  <p className={styles.devToolCardInfoText}>
                    Simple online editor for creating custom scrollbars.
                  </p>
                </div>
              </div>
            </a>
            <a href="https://devicon.dev/">
              <div className={styles.devToolCard}>
                <img
                  src="https://raw.githubusercontent.com/devicons/devicon/master/icons/devicon/devicon-original-wordmark.svg"
                  alt=""
                />
                <div className={styles.devToolCardInfo}>
                  <h2 className={styles.devToolCardInfoHeader}>Devicon</h2>
                  <p className={styles.devToolCardInfoText}>Developer icons.</p>
                </div>
              </div>
            </a>
            <a href="https://screely.com/">
              <div className={styles.devToolCard}>
                <img src="https://screely.com/favicon.ico" alt="" />
                <div className={styles.devToolCardInfo}>
                  <h2 className={styles.devToolCardInfoHeader}>Screely</h2>
                  <p className={styles.devToolCardInfoText}>
                    Add browser frame to your screenshots.
                  </p>
                </div>
              </div>
            </a>
            <a href="https://screenshot.rocks/">
              <div className={styles.devToolCard}>
                <img src="https://screenshot.rocks/favicon.ico" alt="" />
                <div className={styles.devToolCardInfo}>
                  <h2 className={styles.devToolCardInfoHeader}>
                    Screenshot.Rocks
                  </h2>
                  <p className={styles.devToolCardInfoText}>
                    Create beautiful mobile & browser screenshot mockups in
                    seconds
                  </p>
                </div>
              </div>
            </a>
          </div>
          <button onClick={() => navigate("/tools")} className={styles.btn}>
            All tools <span>→</span>
          </button>
        </div>

        {/* <div className={styles.adContainerWide}>
          <a href={ad.href} target="_top">
            <img src={ad.src} width="728" height="90" alt={ad.alt} border="0" />
          </a>
        </div> */}

        <div className={styles.adContainerWide}>
          <AmazonAd />
          {/* <a href="https://www.readingspeedtracker.com/?utm_source=webdevolution&utm_medium=webdevolution&utm_campaign=webdevolution">
            <img
              src="https://i.ibb.co/12vkqm4/reading-speed-tracker-ad.png"
              width="768"
              alt=""
              border="0"
            />
          </a> */}
        </div>
        <div className={styles.adContainerSmall}>
          <a
            href="https://www.kqzyfj.com/click-100376026-14482593"
            target="_top"
          >
            <img
              src="https://www.awltovhc.com/image-100376026-14482593"
              width="250"
              height="250"
              alt=""
              border="0"
            />
          </a>
        </div>
      </div>
    </Layout>
  );
};
export default IndexPAge;
