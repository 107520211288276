// extracted by mini-css-extract-plugin
export var mainContainer = "index-module--mainContainer--Gd7FP";
export var cardInfo = "index-module--cardInfo--AKhlK";
export var btn = "index-module--btn--ZG1LL";
export var devToolsSection = "index-module--devToolsSection--KD9Hl";
export var devToolsList = "index-module--devToolsList--WRDNK";
export var blogArticlesList = "index-module--blogArticlesList---XUhw";
export var blogArticlaCard = "index-module--blogArticlaCard--nGVEX";
export var devToolCard = "index-module--devToolCard--VEJGW";
export var devToolCardInfo = "index-module--devToolCardInfo--uKVz9";
export var devToolCardInfoHeader = "index-module--devToolCardInfoHeader--o6rmG";
export var devToolCardInfoText = "index-module--devToolCardInfoText--Luszp";
export var firstPosts = "index-module--firstPosts--Mlhfd";
export var hideOver1200 = "index-module--hideOver1200--3uY8l";
export var secondContainer = "index-module--secondContainer--Iwgzt";
export var bigThumbnail = "index-module--bigThumbnail--PDWv4";
export var recentHeader = "index-module--recentHeader--roY8-";
export var loadBtn = "index-module--loadBtn--A+FcP";
export var ldsEllipsis = "index-module--ldsEllipsis--q2PDz";
export var ldsEllipsis1 = "index-module--ldsEllipsis1--Zvsal";
export var ldsEllipsis2 = "index-module--ldsEllipsis2--vhdu7";
export var ldsEllipsis3 = "index-module--ldsEllipsis3--5G5E5";
export var postHeader = "index-module--postHeader--oZI9e";
export var link = "index-module--link--Nmubk";
export var smallThumbnailRight = "index-module--smallThumbnailRight--j0ikb";
export var smallThumbnailLeft = "index-module--smallThumbnailLeft--FludP";
export var postImage = "index-module--postImage--JqanN";
export var left = "index-module--left--MZGnA";
export var right = "index-module--right--d10qz";
export var postInfoContainer = "index-module--postInfoContainer--IfeHk";
export var postHeaderSmall = "index-module--postHeaderSmall--EgY7H";
export var postDescription = "index-module--postDescription--ude5g";
export var postDataAndAuthor = "index-module--postDataAndAuthor--RvGNF";
export var postDataAndAuthorSmall = "index-module--postDataAndAuthorSmall--riFKL";
export var adContainer = "index-module--adContainer--AH2fH";
export var adContainerWide = "index-module--adContainerWide--sHOX9";
export var adContainerSmall = "index-module--adContainerSmall--JDLO2";